import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
import SEO from '../components/seo';

import Navbar from '../components/navbar.jsx';
import Hero from '../components/hero.jsx';
import Footer from '../components/footer.jsx';

import { EmailLink, scrollTo } from '../components/helper.js';


const LinkToBranches = () => {
  const [done, setStatus] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#pobocky') {
      scrollTo('section#branches');
    }
    setStatus(true);
  }, [done]);

  return (
    <Link to="/#pobocky" className="nav-link nav-link-icon"
        onClick={(event) => {
          event.preventDefault();
          if (document.querySelector('nav .navbar-collapse').classList.contains('show')) {
            document.querySelector('#navbar_global').click();
          }
          scrollTo('#branches');
        }}>
      <i className="fa fa-shop d-lg-none mr-2" />
      <span className="nav-link-inner--text">Pobočky</span>
    </Link>
  );
}

class Index extends React.Component {
  state = {};

  scrollDown = (event) => {
    const target = event.target.closest('section');
    window.scroll({
      left: 0, top: target.offsetTop + target.offsetHeight,
      behavior: 'smooth'
    });
  }

  componentDidMount() {
    this.setState({
      phone1: 126,
      phone2: 660,
    })
  }

  render() {
    const { helper } = this.props;
    const { phone1, phone2 } = this.state;

    return (
      <React.Fragment>
        <SEO />
        <Navbar>
          <LinkToBranches />
        </Navbar>
        <main ref="main">
          <Hero fullscreen>
            <img alt='logo' className='img-fluid mb-lg-5'
              onClick={this.scrollDown}
              src={require("../assets/img/culkafruit.png")} />
          </Hero>
          <section className="section pb-0 pb-md-5">
            <Container>
              <Row>
                <Col className="mr-lg-3">
                  <h2>O nás</h2>
                  <p className="lead">
                    Predávame domáce aj exotické ovocie a&nbsp;zeleninu,
                    bylinky, oleje aj netypické sladkosti.
                  </p>
                </Col>
                <Col lg="6" className="ml-lg-3">
                  <p>
                    Sme firma fungujúca viac ako 20 rokov. Starostlivo vyberáme
                    ovocie a zeleninu v Rakúsku, Taliansku a&nbsp;v Španielsku.
                    Ponúkame exotické aj domáce druhy ovocia a zeleniny. Rôzne
                    netradičné a&nbsp;tradičné oleje, bylinky, potraviny
                    a&nbsp;sladkosti. Podporujeme aj lokálnych výrobcov, takže
                    produkty sú kvalitnej výroby. Vždy nám ide o to, aby
                    zákazníci boli spokojní. Ceníme si ich chválu aj vítky,
                    ktoré vždy s radosťou príjmeme, aby sme mohli napredovať.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <section id="branches" className="section section-lg">
            <Container>
              <h2>Naše pobočky</h2>
              <p>Tu sa dozviete všetky informácie o našich pobočkách.</p>

              <Card className="shadow border-0" style={{overflow: 'hidden'}}>
                <Row className="align-items-center">
                  <Col md="12" lg="6" className="p-0" style={{minHeight: '500px'}}>
                    <CardBody style={{background: `black url(${require('../assets/img/branches/zelovoc2014.jpg')}) no-repeat center/cover`, minHeight: '500px'}}>
                    </CardBody>
                  </Col>
                  <Col md="12" lg="6">
                    <CardBody>
                      <h5 className="card-title text-primary text-uppercase">
                        Dlhé Diely
                      </h5>

                      <p className="description mt-3">
                        Obchod na Dlhých Dieloch je náš najstarší kamenný
                        obchod, otvorený vyše 20 rokov. Nájdete tu všetky druhy
                        ovocia a zeleniny, potravín, nápojov a sladkostí, ktoré
                        máme v stálej ponuke.
                      </p>

                      <h6>Otváracie hodiny</h6>
                      <p>Pondelok až piatok: 8:00 &ndash; 19:30<br />
                        Sobota: 8:00 &ndash; 17:00<br />
                        Nedeľa: ZATVORENÉ</p>

                      <h6>Kontakt</h6>
                      <p>tel. číslo: 02/653&thinsp;{phone1}&thinsp;11<br />
                        e-mail: <EmailLink to={'dlhediely'} /></p>

                      <a
                        href="https://g.page/zelovocdd" target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h6>Lokácia <i className="fa fa-external-link"></i></h6>
                      </a>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Container>
          </section>
        </main>
        <Footer>
          <h3 className="font-weight-light mb-3">
            Neváhajte nás kontaktovať
          </h3>

          <a
            href={['tel:+43', phone2, '1930700'].join(' ')}
            target="_blank" rel="noopener noreferrer"
            className="btn btn-icon btn-3 btn-primary mb-2"
          >
            <span className="btn-inner--icon">
              <i className="fa fa-phone"></i>
            </span>
            <span className="btn-inner--text">+43&nbsp;{phone2}&nbsp;1930700</span>
          </a>

          <EmailLink type={'button'} className={'btn-google-plus mb-2'}
            to={'culkafruit'} />

          <a
            href="https://www.facebook.com/culkafruit"
            target="_blank" rel="noopener noreferrer"
            className="btn btn-icon btn-3 btn-facebook mb-2"
          >
            <span className="btn-inner--icon">
              <i className="fa fa-facebook"></i>
            </span>
            <span className="btn-inner--text">Culka fruit</span>
          </a>

          <a
            href="https://www.instagram.com/culkafruit/"
            target="_blank" rel="noopener noreferrer"
            className="btn btn-icon btn-3 btn-instagram mb-2"
          >
            <span className="btn-inner--icon">
              <i className="fa fa-instagram"></i>
            </span>
            <span className="btn-inner--text">
              {'\u0040'}culkafruit
            </span>
          </a>
        </Footer>
      </React.Fragment>
    );
  }

}

export default Index;
